<template>
  <div>
    <template v-if="$route.path != '/registrant/packages' && $route.path.includes('/registrant/order-payment') != true">
      <vue-navigation-bar :options="updateProductItems" />
    </template>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        navbarOptions: {
          elementId: 'main-navbar',
          isUsingVueRouter: true,
          mobileBreakpoint: 992,
          brandImagePath: './',
          brandImageAltText: 'brand-image',
          collapseButtonOpenColor: '#661c23',
          collapseButtonCloseColor: '#661c23',
          showBrandImageInMobilePopup: true,
          ariaLabelMainNav: 'Main Navigation',
          tooltipAnimationType: 'shift-away',
          menuOptionsLeft: [
            {
              type: 'link',
              text: 'Profile',
              path: '/registrant/profile',
              iconLeft: '<i class="mdi mdi-account"></i>',
            },
            {
              type: 'link',
              text: 'Products',
              iconLeft: '<i class="mdi mdi-view-dashboard"></i>',
              subMenuOptions: [],
            },
            {
              type: 'link',
              text: 'Orders',
              path: '/registrant/orders',
              iconLeft: '<i class="mdi mdi-account"></i>',
            },
          ],
        },
      }
    },
    created () {
      this.getAllProducts()
    },
    computed: {
      ...mapGetters(['allProducts']),
      updateProductItems () {
        const productItems = this.allProducts.map(product => {
          return {
            type: 'link',
            text: product.name,
            path: `/registrant/products/${product.id}`,
          }
        })
        const navigationItems = this.navbarOptions
        navigationItems.menuOptionsLeft[1].subMenuOptions = productItems
        return navigationItems
      },
    },
    methods: {
      ...mapActions(['getAllProducts']),
    },
  }
</script>

<style lang="scss">
.vnb {
  &__menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
